import React from 'react';

import Carousel from '../../../Components/Carousel/Carousel';

import PlayStore from '../../../Components/PlayStore/PlayStore';
import Image from '../../../Components/Image/Image';

import SOCIANA_LOGO_PNG from '../../../Assets/Landing/sociana1.png';
import SOCIANA_LOGO_WEBP from '../../../Assets/Landing/sociana1.webp';

import LANDING_IMG1 from '../../../Assets/Landing/showcase9.webp';
import LANDING_IMG2 from '../../../Assets/Landing/showcase5.webp';
import LANDING_IMG3 from '../../../Assets/Landing/showcase8.webp';
import {
  LANDING_TEXT_1,
  LANDING_TEXT_2,
  LANDING_TEXT_3,
  LANDING_TEXT_4,
  LANDING_TEXT_5,
  LANDING_TEXT_6
} from '../../../Constants';

import styles from './landing.module.css';

const SLIDES = [
  {
    id: 1,
    img: LANDING_IMG1,
    heading: LANDING_TEXT_1,
    title: LANDING_TEXT_2
  },
  {
    id: 2,
    img: LANDING_IMG2,
    heading: LANDING_TEXT_3,
    title: LANDING_TEXT_6
  },
  {
    id: 3,
    img: LANDING_IMG3,
    heading: LANDING_TEXT_5,
    title: LANDING_TEXT_4
  }
];

const Landing = () => (
  <div data-testid="landing" id={styles.landing}>
    <div className={styles.slider}>
      <Carousel interval={5000} indicators={false}>
        {SLIDES.map((slide, index) => {
          return (
            <div
              key={`${slide}+${index}`}
              className={`${styles.container} container_${index + 1}`}>
              <div className={`${styles.content} center`}>
                <Image
                  className={styles.logo}
                  src={SOCIANA_LOGO_PNG}
                  webpImage={SOCIANA_LOGO_WEBP}
                  alt="logo"
                />
                <p className={`${styles.quote} heading_text white_heading`}>
                  {slide.heading}
                </p>
                <PlayStore />
                <p className={styles.title}>{slide.title}</p>'
              </div>
            </div>
          );
        })}
      </Carousel>
    </div>
  </div>
);

export default Landing;
